import React, {useState} from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';


export default function EventDetailModal(props) {
  return (
    <Modal
      onHide={()=> props.setShow(false)}
      show={props.show}
      size="lg"
      // dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          {props.event_name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{'display':'flex', 'justifyContent':'space-between'}}>
        <div>Key: {props.event_id}</div>
        <br/>
        <div>
          <Button style={{'marginRight':'5px', 'width': '80px'}} onClick={()=>props.view(props.event_id)}>View</Button>
          <Button variant="secondary" style={{'marginLeft':'5px', 'width': '80px'}} onClick={()=>props.delete(props.event_id)}>Delete</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
