import React , { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import classes from './Pages.module.css';
import {NotificationManager} from 'react-notifications';

import {Context} from '../HOCs/Store';

import NextBtn from '../Components/NextBtn';
import CalendarPicker from '../Components/CalendarPicker';
import AttendeeDateSelector from '../Components/AttendeeDateSelector';
import SelectedDateDisplayBoxAttendee from '../Components/SelectedDateDisplayBoxAttendee';



export default function AttendeePage2(props){

    const [state, dispatchState] = useContext(Context);
    // useEffect(() => {
    //     console.log(state)
    // }, [state])   
    const [selectedTimes, setSelectedTimes] = useState({})
    let history = useHistory();

    const Fragment = React.Fragment;


    const saveAttendeeInfo = () => {
        if (state.attendee.location_options === undefined) {
            NotificationManager.error('Please add at least one location', '', 2000);
            return null;
        }
        dispatchState({type: 'LOADING'})
        axios.patch(`${process.env.REACT_APP_FLASK}/events/${state.trip_id}/${state.attendee.attendee_id}`, state.attendee )
            .then(res => {
                console.log(res);
                dispatchState({type: 'NOT_LOADING'})
                if (state.admin_view === true) {
                    history.push(`/events/${state.trip_id}`)
                } else {
                    history.push(`/event-signup-confirm`)
                }
            })
            .catch(error => {
                console.log(error.response)
            });
    };


    if (state.trip_id === '') {return history.push('/')};

    const handleCalendarSelection = (attendee) => {
        dispatchState({type: 'NEW_ATTENDEE', attendee: attendee});
    }

    const handleAttendeeDateSelector = (status, location_id, date_id, selected_date) => {
        let attendeeCopy = state.attendee;
        if (status === true) {
            if (attendeeCopy.location_options.hasOwnProperty(location_id)) {
                attendeeCopy.location_options[location_id]['chosen_dates'][date_id] = selected_date
            } else {
                attendeeCopy.location_options[location_id] = {chosen_dates: {[date_id]: selected_date}}
            }


        } else {
            delete attendeeCopy.location_options[location_id].chosen_dates[date_id]

        } 
        dispatchState({type: 'NEW_ATTENDEE', attendee: attendeeCopy});

    }

    const handleAttendeeCalendarPicker = (workingLocations, startDate, endDate) => {
        console.log(startDate)
        console.log(endDate)
        // console.log()
        let attendeeCopy = state.attendee
        let date_id = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(5, 10);
        for (const [loc_id, location] of Object.entries(workingLocations)) {
            if (attendeeCopy.location_options.hasOwnProperty(loc_id)) {
                if (Object.entries(attendeeCopy.location_options[loc_id].chosen_dates).some(([id, dateSet]) => dateSet[0] === startDate && dateSet[1] === endDate)) 
                    {console.log('skipped');
                    continue;}
                else {
                    console.log('here');
                    attendeeCopy.location_options[loc_id].chosen_dates[date_id] = [startDate, endDate]
                };
            }
            else {
                console.log('other here');
                attendeeCopy.location_options[loc_id] = {location_name: location.location_name, chosen_dates : { [date_id]: [startDate, endDate] } }
            }
        }
        dispatchState({type: 'NEW_ATTENDEE', attendee: attendeeCopy});
        console.log(state.attendee)
    }

    const removeDateFromLocation = (loc_id, date_id) => {
      let attendeeCopy = state.attendee;
      delete attendeeCopy.location_options[loc_id].chosen_dates[date_id];
      console.log(attendeeCopy)
      dispatchState({type: 'NEW_ATTENDEE', attendee: attendeeCopy });

    }

    return (
        <div className={classes.PlannerPageStart}>
            <div className={classes.MainHeading} onClick={() => {console.log(state)}}>{state.event.event_name}</div>
            <hr className={classes.DividerLine} />
            <div className={classes.MainHeading}>Select where and when you can go:</div>
            {state.event.date_planned === false &&
                <Fragment>
                <CalendarPicker event_data={state.event} handler={handleAttendeeCalendarPicker} />
                <SelectedDateDisplayBoxAttendee locations={state.attendee.location_options} delete={removeDateFromLocation} />
                </Fragment>
            }
            {state.event.date_planned === true &&
                <AttendeeDateSelector event_data={state.event} handler={handleAttendeeDateSelector} currentAttendee={state.attendee} />
            }
            <NextBtn back={true} handler={saveAttendeeInfo} event_data={state.event} text='Submit'/>
        </div>
    )
}