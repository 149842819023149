import React, {useContext, useState} from 'react';
// import classes from './Pages.module.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { SignIn } from "@clerk/clerk-react";
// import { withClerk } from "@clerk/clerk-react";

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {Context} from '../HOCs/Store';


// const ClerkModal = (props) => {
//     const [lgShow, setLgShow] = useState(false);
// 
//     return (    
//         <>
//         <Button onClick={() => setLgShow(true)}>Sign in</Button>
//         <Modal
//             size="lg"
//             show={lgShow}
//             onHide={() => setLgShow(false)}
//             aria-labelledby="example-modal-sizes-title-lg"
//         >
//         <Modal.Header closeButton>
//           <Modal.Title id="example-modal-sizes-title-lg">
//             Large Modal
//           </Modal.Title>
//         </Modal.Header>
//         <Modal.Body><SignUp/></Modal.Body>
//       </Modal>
//       </>   
//       )
// }

export default function ClerkLoginPage(props) {
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();




    return (<div>
                <SignIn />
            </div>
    );
}

