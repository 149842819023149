import React, {useContext, useState} from 'react';
// import classes from './Pages.module.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { UserProfile } from "@clerk/clerk-react";
// import { withClerk } from "@clerk/clerk-react";

import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';
import {Context} from '../HOCs/Store';



export default function User(props) {
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();


    return (<div>
                <UserProfile />
            </div>
    );
}

