import React from "react";
import classes from './Pages.module.css';
import {ManageEventCodeInput} from '../Components/ManageEventCodeInput'

export const ManageEvent = (props) => {

    return (
          <div className={classes.CodeEntry}>
            <div className={classes.MainHeading}>Manage Event</div>
            <div className={classes.SubHeading}>Enter your event code here</div>
            <ManageEventCodeInput />
          </div>
    )


};

