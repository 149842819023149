import React from 'react'
import classes from './Components.module.css'
import { FaFacebookF, FaDiscord } from "react-icons/fa";
import { FcReddit } from "react-icons/fc";



function Footer (props) {
    return (
            <div className={classes.footer}>
                <p className={classes.footerBlock}>
                    Copyright © Skej 2023
                     <a href='https://www.facebook.com/TheSkejCompany'>
                        <FaFacebookF className={classes.FooterItem} size={14}/>
                    </a>
                     <a href='https://discord.gg/8CaYWHXSUK'>
                        <FaDiscord className={classes.FooterItem} size={14}/>
                    </a>
                     <a href='https://www.reddit.com/r/Skej/'>
                        <FcReddit className={classes.FooterItem} size={14}/>
                    </a>
                     
                </p>
                    
            </div>
        );
}

export default Footer;