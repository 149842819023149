import React, {useContext} from 'react'
import classes from './Components.module.css'
import { Link, useHistory } from "react-router-dom";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io';
import Button from 'react-bootstrap/Button';
import {Context} from '../HOCs/Store';


const BackBtn = (props) => {
    let history = useHistory()
    if (props.back !== true) {
        return null
    } else {
        return <Button variant="success" className={classes.BackBtn} onClick={() => history.goBack()}>Back</Button>
        }
    }


function NextBtn (props) {
    let history = useHistory()
    const [state] = useContext(Context);

    // if (state.isLoading === true){
    //     return (
    //         <Button variant="success" size="lg" disabled>
    //             <span className="sr-only">Loading...</span>
    //         </Button>
    //     )
    // }
        return (
            <div className={classes.NavBtns}>
                <Button variant="success" disabled={state.isLoading}
                        className={classes.NextBtn} 
                        onClick={props.handler}>
                        {props.text}
                </Button>
                <div>
                    <BackBtn back={props.back}/>
                </div>
            </div>
        )
}

export default NextBtn;
