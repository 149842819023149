import React , { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import classes from './Pages.module.css';
import {NotificationManager} from 'react-notifications';
import * as EmailValidator from 'email-validator';
import Spinner from "react-bootstrap/Spinner";

import {Context} from '../HOCs/Store';

import NameEntry from '../Components/NameEntry';
import AttendeeEmailEntry from '../Components/AttendeeEmailEntry';
import NextBtn from '../Components/NextBtn';

export default function AttendeePage1(props){
    // const [isLoading, setLoading] = useState(true);
    const [state, dispatchState] = useContext(Context);    
    let history = useHistory();
    const Fragment = React.Fragment;

    useEffect(() => {
        if (state.attendee.name === '') {
            dispatchState({type: 'LOADING'});
            axios.get(`${process.env.REACT_APP_FLASK}/signup/${props.location.pathname.split("/")[2]}`)
                .then((response) => {
                    dispatchState({type: 'NOT_LOADING'});
                    if (response.data === null) {
                        dispatchState({type: 'ATTENDEE', event: 'No trip found', trip_id: props.location.pathname.split("/")[2]});
                    } else {
                        dispatchState({type: 'ATTENDEE', 
                            event: response.data, 
                            trip_id: props.location.pathname.split("/")[2],
                            attendee: {name: '', email: '', location_options: {}}});
                    }
                })
                .catch(error => {
                    dispatchState({type: 'ATTENDEE', event: 'Error Occurred', trip_id: props.location.pathname.split("/")[2]});
                    console.log(error.response);
                    dispatchState({type: 'NOT_LOADING'});
               })
        }
        
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

    const viewEvent = () => {
        history.push(`/events/${state.trip_id}`)
    }


    function addAttendee(props) {
        // addLocationsAndDatesToAttendee()
        if (!(checkValidations())) {
            return null
        }
        console.log(state.attendee.attendee_id !== undefined);
        // check if attendee has already been posted 
        if (state.attendee.attendee_id !== undefined) {
            history.push('/event-signup-2');
        } else {
            dispatchState({type: 'LOADING'});
            axios.post(`${process.env.REACT_APP_FLASK}/signup/${state.trip_id}`, state.attendee )
                    .then(res => {
                        dispatchState({type: 'NOT_LOADING'});
                        console.log(res.data);
                        let attendeeCopy = state.attendee
                        attendeeCopy.attendee_id = res.data.name
                        dispatchState({type: 'NEW_ATTENDEE', attendee: attendeeCopy});
                    })
                    .catch(error => {
                        dispatchState({type: 'NOT_LOADING'});
                        console.log(error.response)
                    })
            history.push('/event-signup-2');
        }

    };

    const updateForm = (e) => {
        let attendeeCopy = state.attendee
        switch (e.target.name) {
            case 'attendee_name':
                attendeeCopy.name = e.target.value;
                break;
            case 'attendee_email':
                attendeeCopy.email = e.target.value;
                break;
            default:
                console.log('unhandled case');

        };
        dispatchState({type: 'NEW_ATTENDEE', attendee: attendeeCopy});
    };

    const checkValidations = () => {
        if (EmailValidator.validate(state.attendee.email) || state.attendee.email === '') {
            
        } else {
            console.log('here')
            NotificationManager.error('Please enter a valid email', '', 2000);
            return false;
        }
        if (state.attendee.name === '') {
            NotificationManager.error('Please enter your name', '', 2000);
        }
        return true;
    }
    // if (state.attendee === undefined) {
    //     dispatchState({type: 'NEW_ATTENDEE', attendee: {name: '', email: ''}})
    // }

    if (state.event === 'No trip found') {return <div className={classes.MainHeading}>No trip found</div>};
    if (state.event === 'Error Occurred') {return <div className={classes.MainHeading}>Error Occurred</div>};
    
    if (state.isLoading === true) {return ( 
            <div className="App">
            <div className={classes.MainHeading}><br/></div>;
            <Spinner animation="border" />
            </div>
        )};
    // if (state.trip_id !== '') {dispatchState({type: 'NOT_LOADING'})};



    return (<div className={classes.PlannerPageStart}>
                <div className={classes.MainHeading} onClick={() => {console.log(state)}}>{state.event.event_name}</div>
                <hr className={classes.DividerLine} />
                <div className={classes.SubHeading}>Enter the name you want to be seen as:</div>
                <NameEntry text='Enter your name' handler={updateForm} handleEnter={addAttendee} attendeeName={state.attendee.name}/>
                <hr className={classes.DividerLine} />
                <div className={classes.Subheading}>Optional: Enter your email to get updates about this trip</div>
                <AttendeeEmailEntry text='Enter your email' handler={updateForm} attendeeEmail={state.attendee.email}/>
                <div><NextBtn link={''} handler={addAttendee} event_data={state.event} text='Next'/></div>
                {state.event.attendee_can_view === true &&
                <Fragment>
                    <hr className={classes.DividerLine} />
                    <div><NextBtn link={''} handler={viewEvent} text='Already Signed Up?'/></div>
                </Fragment>
                }
            </div>)
}   