import React from 'react';
import classes from './Components.module.css';
import dayjs from 'dayjs'
import { ReactFitty } from "react-fitty";
import {datePrepare} from '../Special/day_formatting'

export default function AttendeeDateSeletor(props) {

    const handleCheckBox = (checked, location, date_id, date) => {
        console.log(checked, location, date_id, date)
        props.handler(checked, location, date_id, date)
    }

    const divClickCheckHandler = (e, location_id, date_id, date) => {
        if (e.target.type === "checkbox") {return null};
        let inputElement = e.target.getElementById("dcb"+date_id)
        inputElement.checked = !(inputElement.checked)
        handleCheckBox(inputElement.checked, location_id, date_id, date)
    }

    const isSelectedDate = (loc_id, date_id) => {
        if (loc_id in props.currentAttendee.location_options) {
            if (date_id in props.currentAttendee.location_options[loc_id].chosen_dates) {
                return true;
            }
        }
        return false;

    }

    
    function listGen([loc_id,location], index) {
        return Object.entries(location.dates).map(([date_id, date], sec_index) => {
            return (<div className={classes.DateBox} 
                key={`${location.location_id} ${sec_index}`}
                onClick={(e) => divClickCheckHandler(e, location.location_id, date_id, date)} >
                            <div className={classes.DateBoxText}><ReactFitty maxSize={16}>{location.location_name}</ReactFitty></div>
                            <div className={classes.DateBoxDates}>
                                <ReactFitty maxSize={16}>
                                    {datePrepare(date)}
                                </ReactFitty>
                            </div>
                            <div className={classes.DateBoxCheckDiv}> 
                                <input className={classes.DateCheckbox} id={"dcb"+date_id}
                                    type="checkbox"
                                    checked={isSelectedDate(loc_id, date_id)}
                                    onChange={()=> {return null}}
                                    onClick={(e) => handleCheckBox(e.target.checked, location.location_id, date_id, date)}>
                                </input>
                            </div>
                        </div>
            )
        })
    }

    return (
        <div className={classes.DateBoxContainer}>
            {Object.entries(props.event_data.location_options).map(listGen)}
        </div>
    )
}