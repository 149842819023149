// import { Navbar, Nav, NavItem, MenuItem, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import React , { useContext } from "react";
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { UserButton, useClerk, SignedIn, SignedOut } from "@clerk/clerk-react";

import {Context} from '../HOCs/Store';
import classes from './Components.module.css'



function SkejNavbar(){
  const [state, ] = useContext(Context);
  const clerk = useClerk();



  const soundOff = () => {
    console.log(clerk)
  }

  return (
    <Navbar collapseOnSelect className = {classes.Skejnav} variant="dark" expand = 'lg' sticky = 'top' onClick={soundOff}>
      <Navbar.Brand as={Link} to="/">Skej</Navbar.Brand>
      <div className={classes.ProfileMenuPair}>
      <SignedIn><Nav.Link><UserButton /></Nav.Link></SignedIn>
      
      <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
      </div>
      <Navbar.Collapse id="responsive-navbar-nav" >
        <Nav className= {classes.Menu + " mr-auto"}>
          <Nav.Link eventKey="1" as={Link} to="/">Home</Nav.Link>
          <Nav.Link eventKey="2" as={Link} to="/plan-event-1">Plan Event</Nav.Link>
          {state.admin_view === true &&
            <Nav.Link eventKey="3" as={Link} to="/manage-event">Manage Event</Nav.Link> 
          }
          <Nav.Link eventKey="4" as={Link} to="/about">About</Nav.Link>
          <SignedOut><Nav.Link eventKey="5" as={Link} to='/sign-in'>Sign In</Nav.Link></SignedOut>
          <SignedIn><Nav.Link eventKey="6" as={Link} to='/events'>My Events</Nav.Link></SignedIn>


        </Nav>
      </Navbar.Collapse>
        {/* <Button onClick={soundOff}>Log</Button> */}

    </Navbar>
    )
}

export default SkejNavbar;