import React , { useEffect, useContext } from "react";
import axios from 'axios';
import classes from './Pages.module.css';
import {NotificationManager} from 'react-notifications';
import { useHistory } from "react-router-dom";
import * as EmailValidator from 'email-validator';
import { useClerk } from "@clerk/clerk-react";


import {Context} from '../HOCs/Store';

import EventNameEntry from '../Components/EventNameEntry';
import EventEmailEntry from '../Components/EventEmailEntry';
import NextBtn from '../Components/NextBtn';


export default function PlannerPage1(props) {
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();
    const clerk = useClerk();

    useEffect(()=> {
      if (state.trip_id === '') {
          dispatchState({type: 'TRIP_ID', trip_id: ' '})
        }
      if ((clerk.clerkjs.session) !== null) {
        console.log('here')
        let stateCopy = state
        stateCopy.event.event_leader_email = clerk.clerkjs.session.user.primaryEmailAddress.emailAddress;
        dispatchState({type: 'EVENT_DATA', event: stateCopy.event});
              }
      }, []);

    const updateForm = (e) => {
      let stateCopy = state
      switch (e.target.name) {
        case 'event_name':
          stateCopy.event.event_name = e.target.value;
          break;
        case 'event_leader_email':
          stateCopy.event.event_leader_email = e.target.value;
          break;
        case 'location_options':
          stateCopy.event.location_options = e.target.value;
          break;
        default:
          console.log(e.target.name);
          console.log(e.target.value);

      };
          dispatchState({type: 'EVENT_DATA', event: stateCopy.event});


    };

    

  function createEvent(eventData) {
    if (state.event.event_name === '') {
      NotificationManager.error('Please enter a name for your event', '', 2000);
      console.log(state);
      return null
    };
    if (state.event.event_leader_email !== '') {
      if (!(EmailValidator.validate(state.event.event_leader_email))) {
        NotificationManager.error('Please enter a valid email', '', 2000);
        return null;
      };
    }
    if (state.trip_id === ' ') {
      dispatchState({type: 'LOADING'});
      axios.post(`${process.env.REACT_APP_FLASK}/create-event`, state.event )
        .then(res => {
          dispatchState({type: 'NOT_LOADING'})
          console.log(res);
          dispatchState({type: 'TRIP_ID', trip_id: res.data.name});
        })
        .catch(error => {
            console.log(error);
            dispatchState({type: 'NOT_LOADING'});
        });
      }
      console.log(state);       
      history.push(`/plan-event-2`);
  };


  function soundOff() {
    console.log(state);
  }
  console.log('here')


  return (<div className={classes.PlannerPageStart}>
                  <div className={classes.MainHeading} onClick={soundOff}>What's the name of your event?</div>
                  <hr className={classes.DividerLine} />
                  <div><div className={classes.EgBox}>e.g. Chris's Birthday Party 
                          <br/>&nbsp;&nbsp;   Family Football Tailgate 
                          <br/>&nbsp;&nbsp; Daniel's Bachelor Party</div></div>
                  <EventNameEntry save={updateForm} eventName = {state.event.event_name}/>
                  <hr className={classes.DividerLine} />
                  <div className={classes.MainHeading}>What's a good email to send this trip to?</div>
                  <EventEmailEntry save={updateForm} email={state.event.event_leader_email} text='Enter email here'/>  <br/>
                  <NextBtn link={''}
                    handler={createEvent}
                    data={state.event}
                    text='Next'/>
              </div>)
}
