import React from 'react'
import classes from './Components.module.css'

export default function EventEmailEntry (props) {

    const handleChange = (e) => {
        props.save(e)
    }

    return (
        <input className={classes.EntryBox}
            type="email"
            id="email_entry"
            name="event_leader_email"
            placeholder={props.text}
            value={props.email}
            onChange={handleChange} />
    );
}
