import React, { useState } from 'react'
import classes from './Components.module.css'

export default function EventNameEntry (props) {
    const [eventName, setEventName] = useState(props.eventName)

    const handleChange = (e) => {
        props.save(e)
        setEventName(e.target.value)
    }

    return (
        <input className={classes.EntryBox} 
            type="text" 
            name="event_name" 
            placeholder='Enter event name here' 
            value={eventName}
            onChange={handleChange} />
    );
}
