import React, {useContext} from 'react';
import { Link } from "react-router-dom";



export default function PlanYourEventBtn(props) {
    return ( <button type="button" className="btn btn-success" onClick={props.handler}>
                    Plan Your Event! 
            </button>
    )
}