import React, {useState, useEffect, useContext} from "react";
import classes from './Pages.module.css';
import ListGroup from 'react-bootstrap/ListGroup';
import axios from 'axios';
import { useClerk, useUser } from "@clerk/clerk-react";
import { TiDelete } from "react-icons/ti";
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";

import {Context} from '../HOCs/Store';

import EventDetailModal from '../Modals/EventDetailModal'
import ConfirmDelete from '../Modals/ConfirmDelete';


export default function UserEvents(props) {
    const user = useUser();
    const { session } = useClerk();
    const sessionId = session.id;
    let history = useHistory();
    const [events, setEvents] =  useState({});
    const [show, setShow] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [activeModal, setActiveModal] = useState({});
    const [toDelete, setToDelete] = useState('');
    const [state, dispatchState] = useContext(Context);
    const Fragment = React.Fragment;


    useEffect(()=> {
        dispatchState({type: 'LOADING'});
        axios.get(`${process.env.REACT_APP_FLASK}/load-events/${user.primaryEmailAddress.emailAddress}`,{
            params:{
                _clerk_session_id:sessionId
            }
        })
            .then(res => {
                console.log(res);
                setEvents(res.data.events);
                dispatchState({type: 'NOT_LOADING'});
            })
            .catch(error => {
                console.log(error);
                dispatchState({type: 'EVENT_DATA', event: 'Error encountered'});
                dispatchState({type: 'NOT_LOADING'});
            })
    }, [])



    const listGroupGen = ([event_id, event_name]) => {
        return (
            <ListGroup.Item action 
                // href={`/events/${event_id}`} 
                key={event_id}
                onClick={(e => {
                    setActiveModal({event_id:event_id, event_name:event_name});
                    setShow(true);
                    })}>
              <div className={classes.EventListItem}>
                {event_name}
              </div>
            </ListGroup.Item>
        )
    }

    const viewEvent = (event_id) => history.push(`/events/${event_id}`);


    const deleteEvent = (event_id) => {
        setToDelete(event_id);
        setShowDelete(true);
    }

    const confirmDelete = () => {
        setShowDelete(false);
        setShow(false);
        axios.delete(`${process.env.REACT_APP_FLASK}/delete-event/${toDelete}`)
            .then(res => {
                console.log(res);
                if (res.status == 200){
                    let eventsCopy = events;
                    delete eventsCopy[toDelete]
                    setEvents(eventsCopy);
                    setToDelete('');
                }})
            .catch(error => console.log(error))
    }

    const cancelDelete = () => {
        setToDelete('');
        setShowDelete(false);
    }

    const soundOff = () => console.log(events)

    return (
          <div>
            <div className={classes.MainHeading} onClick={soundOff}>My Events</div>
            <ListGroup style={{width:"80%", 'margin':'auto'}}>
                {Object.entries(events).map(event => listGroupGen(event))}            
            </ListGroup>
            {state.isLoading === true && <Spinner animation="border" />}
            {state.event === 'Error encountered' && <div className={classes.SubHeading}>Error encountered</div>}
            {activeModal !== {} &&
              <Fragment>
                <EventDetailModal event_name={activeModal.event_name}
                    event_id={activeModal.event_id}
                    show={show}
                    setShow={setShow}
                    view={viewEvent}
                    delete={deleteEvent}
                />
                <ConfirmDelete yes={confirmDelete} cancel={cancelDelete} show={showDelete} onHide={()=> setShowDelete(false)}/>
              </Fragment>
            }
          </div>
    )


};

//defaultActiveKey="#link1"