import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export default function ConfirmDelete(props) {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="sm"
      // dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Are you sure?
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{'textAlign':'center'}}>
        <Button variant="danger" style={{'marginRight':'5px', 'width': '80px'}} onClick={props.yes}>Yes</Button>
        <Button style={{'marginLeft':'5px', 'width': '80px'}} onClick={props.cancel}>Cancel</Button>
      </Modal.Body>
    </Modal>
  );
}
