import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import {Context} from '../HOCs/Store';

import classes from './Components.module.css'

export default function AttendeeModal(props) {
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();

    const reroute = () => {
        const reset_attendee = {
                name: '', 
                email: '',
                location_options: []
            } 
        dispatchState({type: 'NEW_ATTENDEE', attendee: reset_attendee})
        history.push(`/event-signup/${state.trip_id}`)
    };
    
    return (<Button variant="primary" onClick={reroute} className={classes.ModalBtn}>
                + Enter new attendee availability
        </Button>
    )}
