import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classes from './ModalStyles.module.css'

function AttendeeDetailModal(props) {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter" onClick={props.soundoff}>
          {props.data.name}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.data.locations !== undefined &&
        <div>{props.data.locations.map((location, index) => {
          // let loc = props.locations.filter(l => l.location_id === location[0]);
          let name = props.locations[location[0]].location_name
            return (<div key={index}>{`${name}: ${location[1]}-${location[2]}`}</div>)
          })}
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.editAttendee}>Edit</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function AttendeeDetails(props) {
  // if (props.data.locations !== undefined) {
  //   const sorted_locations = props.data.locations.sort()
  // };
  const soundoff = () => {
    console.log(props.originalAttendee)
    console.log(props.data)
  } 

  const editAttendee = () => {
    props.editAttendee(props.originalAttendee);
  }

  return (
      <AttendeeDetailModal
        show={props.show}
        onHide={props.hide}
        data={props.data}
        locations={props.locations}
        editAttendee={editAttendee}
        soundoff={soundoff}
        />
  );
}
