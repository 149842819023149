import React , { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import classes from './Pages.module.css';
import {NotificationManager} from 'react-notifications';

import {Context} from '../HOCs/Store';

import NextBtn from '../Components/NextBtn';



export default function AttendeePageConfirm(props){
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();
    let nextBtnText = 'Exit'
    if (state.admin_view === true || state.event.attendee_can_view == true) {
        nextBtnText = 'View the Event!'
    }

    const returnHome = () => {
        if (state.admin_view === true || state.event.attendee_can_view == true) {
            history.push(`/events/${state.trip_id}`)
        } else {
            history.push('/')        
        }
    }
        return (
        <div className={classes.PlannerPageStart}>
            <div className={classes.MainHeading} onClick={() => {console.log(state)}}>{state.event.event_name}</div>
            <hr className={classes.DividerLine} />
            <div className={classes.MainHeading}>Thanks for signing up!</div>
            <div className={classes.Textbox}>Your details have been recorded. If you need to make any changes, click the back arrow now or let the event planner know.</div>
            <NextBtn back={true} handler={returnHome} text={nextBtnText}/>
        </div>
    )
}
