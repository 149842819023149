import React, {useState, useEffect} from 'react'
import classes from './Components.module.css'


export default function LocationBox (props) {
	// console.log('running LocationBox component')
	// console.log(props)
  const [locationsState, updateLocationState] = useState(props.location_options);
  useEffect(() => {
    if (props.fromModal === true) {
      props.save(locationsState)
    } else {
      props.save({target: { 'value': locationsState, 'name': 'location_options', 'type': 'text' }})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationsState])

	const [newLocationState, updateNewLocation] = useState( {
      "location_id" : "",
      "location_name" : "",
      "date_sets": {}}
      );

    const setLocation = (e: ChangeEvent<HTMLInputElement>) => {
      updateNewLocation({
        'location_id': getDateId(),
        'location_name': e.target.value
      });
    };

      const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          submitLocation()
        }
      }


    const submitLocation = () => {
      if (document.getElementById("newEvent").value === "") {return null};
      // let location_id = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(5, 10);
      updateNewLocation({
        'location_id': newLocationState.location_id,
        'location_name': newLocationState.location_name
      });
      if (newLocationState.location_name === '' || newLocationState.location_name === undefined){
        return null};
      updateLocationState({...locationsState, [newLocationState.location_id]:newLocationState});
      document.getElementById("newEvent").value = "";
    }

    const getDateId = () => {
      return Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(5, 10);
    }

	function boxGen([id, location], index) {
		return (<div className={classes.LocationBox} key={id} id={id}>
						{index + 1}) {location.location_name}
						<button type="button" className={classes.xBtn} onClick={() => deleteLocation(id)}>X</button>
					</div>)
	};

	function deleteLocation(location_id) {
    let stateCopy = Object.assign({}, locationsState);
    delete stateCopy[location_id];
    updateLocationState(stateCopy);
    //for some reason the useEffect isnt getting triggered when deleting properties and then updating state (not a mutation?)
    if (props.fromModal === true) {
      console.log(stateCopy);
      props.save(stateCopy)
    } else {
      props.save({target: { 'value': stateCopy, 'name': 'location_options', 'type': 'text' }})
    }

	};

  let modalClass = classes.LocationBoxesDiv
  // console.log(props.fromModal)
  if (props.fromModal === true) {
    modalClass = classes.LocationBoxModalDiv
  }

	return (<div>
				<div className={modalClass}>
					{Object.entries(locationsState).map(boxGen)}</div>
				<input className={classes.EntryBox}
          id="newEvent"
          type="text"
          name="location_options"
          placeholder="Enter Location"
          onChange={setLocation}
          onKeyDown={handleKeyDown}/>

	            <button type="button" onClick={submitLocation}>Add</button> 
	        </div>

	)
};