import React from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import {
  ClerkProvider, ClerkLoaded, SignedIn, SignedOut
} from "@clerk/clerk-react";


import {NotificationContainer} from 'react-notifications';


import classes from './App.module.css'
import 'bootstrap/dist/css/bootstrap.min.css';

import SkejNavbar from './Components/Navbar';
import Footer from './Components/Footer';

import Store from './HOCs/Store';

import StartPage from './Pages/StartPage';
import PlannerPage1 from './Pages/PlannerPage1';
import PlannerPage2 from './Pages/PlannerPage2';
import PlannerPage3 from './Pages/PlannerPage3';
import PlannerPageCalendar from './Pages/PlannerPageCalendar';

import EventHomeScreen from './Pages/EventHomeScreen';
import AttendeePage1 from './Pages/AttendeePage1';
import AttendeePage2 from './Pages/AttendeePage2';
import AttendeePageConfirm from './Pages/AttendeePageConfirm';
import {ManageEvent} from './Pages/ManageEvent';
import NotFound from './Pages/404';
import About from './Pages/About';
import ClerkLoginPage from './Pages/ClerkLoginPage';
import ClerkSignUpPage from './Pages/ClerkSignUpPage';
import ClerkUser from './Pages/ClerkUser';
import UserEvents from './Pages/UserEvents';

function App() {
  return (
    <ClerkProviderWithNavigate>
      <ClerkLoaded>
      <Store>
        <div className={classes.Main}>
          <SkejNavbar />
          <NotificationContainer/>
            <SignedIn>
              <Switch>
                <Route exact path="/"><StartPage /></Route>
                <Route path="/about" component={About} />
                <Route path="/sign-in" component={ClerkLoginPage} />
                <Route path="/sign-up" component={ClerkSignUpPage} />
                <Route path="/user" component={ClerkUser} />
                <Route path="/plan-event-1" component={PlannerPage1} />
                <Route path="/plan-event-2" component={PlannerPage2} />
                <Route path="/plan-event-3" component={PlannerPage3} />
                <Route path="/plan-event-dates" component={PlannerPageCalendar} />
                <Route path="/event-signup" component={AttendeePage1} />
                <Route path="/event-signup-2" component={AttendeePage2} />
                <Route path="/event-signup-confirm" component={AttendeePageConfirm} />
                <Route path="/manage-event" component={ManageEvent} />
                <Route path='/events/:handle' component={EventHomeScreen} />
                <Route path="/events" component={UserEvents} />
                <Route path='/:handle'><NotFound/></Route>
              </Switch>
            </SignedIn>
            <SignedOut>
              <Switch>
                <Route exact path="/"><StartPage /></Route>
                <Route path="/about" component={About} />
                <Route path="/sign-in" component={ClerkLoginPage} />
                <Route path="/sign-up" component={ClerkSignUpPage} />
                <Route path="/user" component={ClerkUser} />
                <Route path="/plan-event-1" component={PlannerPage1} />
                <Route path="/plan-event-2" component={PlannerPage2} />
                <Route path="/plan-event-3" component={PlannerPage3} />
                <Route path="/plan-event-dates" component={PlannerPageCalendar} />
                <Route path="/event-signup" component={AttendeePage1} />
                <Route path="/event-signup-2" component={AttendeePage2} />
                <Route path="/event-signup-confirm" component={AttendeePageConfirm} />
                <Route path="/manage-event" component={ManageEvent} />
                <Route path='/events/:handle' component={EventHomeScreen} />
                <Route path='/:handle' component={NotFound} />
              </Switch>
            </SignedOut>
          {/* <Route path="/products"><Products /></Route> */}
          <Footer />
        </div>
      </Store>
      </ClerkLoaded>
    </ClerkProviderWithNavigate>
      );
}

function ClerkProviderWithNavigate({ children }) {
  console.log(process.env)
  const { push } = useHistory();
  return (
    <ClerkProvider
      frontendApi={process.env.REACT_APP_CLERK_FRONT}
      navigate={(to) => {
        return push(to);
      }}
    >
      {children}
    </ClerkProvider>
  );
}


export default App;
