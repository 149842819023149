import React from 'react';
import classes from './Components.module.css';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';




export default function CopyBox(props) {

    return (
        <CopyToClipboard text={props.linktext}>
            <div className={classes.CopyBox} 
                onClick={() => NotificationManager.success('Copied to clipboard!', '', 2000)}>
                {props.linktext}
            </div>
        </CopyToClipboard>
    );
}