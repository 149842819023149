import React, { useState, useContext } from 'react'
import { MdDeleteForever } from "react-icons/md";
import * as EmailValidator from 'email-validator';
import axios from 'axios';

import classes from './Components.module.css'
import {Context} from '../HOCs/Store';
import {NotificationManager} from 'react-notifications';




export default function AttendeeEmails (props) {
    // const [email, setEmail] = useState('')
    const [newEmail, setNewEmail] = useState('')
    const [emails, setEmails] = useState({})
    const [state, dispatchState] = useContext(Context)

    // useEffect(() => {
    //     console.log(newEmail)
    // }, [newEmail])
    

    const addEmailToList = () => {
        console.log(state.emails);
        let email_id = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(5, 10);
        if (EmailValidator.validate(newEmail)) {
            console.log(newEmail);
            if(Object.values(state.emails).includes(newEmail)) {
                    setNewEmail('');
            // setEmails({...emails, [email_id]:newEmail})}
            } else {
                let emails = {...state.emails, [email_id]:newEmail}
                dispatchState({type: 'ATTENDEE_EMAILS', emails: emails})
                setNewEmail('');
            }
        } else {
            NotificationManager.error('Please enter a valid email address', '', 2000);

        };

    }

    const handleChange = (e) => {
        setNewEmail(e.target.value)
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            addEmailToList();
        }
    }

    const removeEmail = (id) => {
        let emailsCopy = Object.assign(state.emails);
        delete emailsCopy[id];
        dispatchState({type: 'ATTENDEE_EMAILS', emails: emailsCopy});
    }

    const sendEmails = () => {
        axios.post(`${process.env.REACT_APP_FLASK}/trigger_attendee_emails/${state.trip_id}`, {emails: state.emails, event_name: state.event.event_name})
          .then(res => {
            console.log(res.status);
          })
          .catch(error => {
             console.log(error.response)
          });
    }

    return (
        <div>
            <div>
                {Object.entries(state.emails).map(([id, email], index) => (
                    <div key={id}>{email} <MdDeleteForever onClick={() => removeEmail(id)}/></div>
                ))}
            </div>
        <input className={classes.EntryBox} 
            type="text"
            id="email_entry"
            name="email_entry"
            // placeholder={props.text}
            onKeyDown={handleKeyDown}
            value={newEmail}
            onChange={handleChange} />
        <button type="button" 
            className={" btn btn-light"} 
            onClick={addEmailToList}>
            Add</button>
        <div>
            <button type="button"
                className={" btn btn-success"} 
                onClick={sendEmails}>
                Send Emails</button>
        </div>
        </div>
    );
}
