import React from 'react'
import Form from 'react-bootstrap/Form';

export default function AllowAttendeesToView(props) {
    const handleCheck = (e) => {
        props.handler(e.target.checked);
    }
    return (
            <Form>
              <Form.Check 
                type="checkbox"
                id="allow_attendees_to_view"
                label="Allow Attendees to View the Event"
                onChange={handleCheck}
                defaultChecked={true}
                // disabled={disabled}
            />
            </Form>
        )
}