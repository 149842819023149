import React from 'react';
import classes from './Pages.module.css';

export default function About(props) {
    console.log(props)
    return (    
            <div className={classes.WelcomePageStart}>
                <div className={classes.MainHeading}>Skej</div>
                <div className={classes.SubHeading}>Where friends plan events</div>
                <div className={classes.AboutBox}>Skej was started with the simple idea that planning an event among friends 
                    and family should be easy, quick, and free from mistakes. 
                    Whenever we want to plan a trip, the hassle of reaching out to everyone, getting their 
                    available dates and cross referencing that with everyone else's available dates is 
                    tedious and exhausting. Some of these trips never get planned at all. 
                <br/> 
                <br/>
                We hope that Skej will remove the tedious parts so the focus can be put on planning the
                 great event rather than just talking about it.</div>
            </div>
    );
}