import React, {createContext, useReducer} from "react";
import Reducer from '../Special/Reducer'


const initialState = {
    'trip_id' : '',
    'admin_view': false,
    'event': {
        "attendees": {},
        "date_planned" : false,
        "location_options": [],
        "owner": "demo@demo.com",
        "event_leader": "",
        "event_leader_email": "",
        "event_id": "",
        "event_name": "",
        "attendee_can_view": true,
    },
    'attendee' : {
        name: '', 
        email: '',
        location_options: {}
    },
    'emails' : {},
    'isLoading' : false
};


const Store = ({children}) => {
    const [state, dispatch] = useReducer(Reducer, initialState);
    return (
        <Context.Provider value={[state, dispatch]}>
            {children}
        </Context.Provider>
    )
};

export const Context = createContext(initialState);
export default Store;