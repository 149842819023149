import React from 'react'
import classes from './Components.module.css'

export default function NameEntry (props) {
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          props.handleEnter()
        }
      }


    return (
        <input className={classes.EntryBox}
            id="attendee_name_box"
            name="attendee_name"
            value = {props.attendeeName}
            type="text"
            placeholder={props.text}
            onChange={props.handler}
            onKeyDown={handleKeyDown} />
    );
}
