import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import classes from './Pages.module.css';
import axios from 'axios';

import {Context} from '../HOCs/Store';

import EventTable from '../Components/EventTable';
import EventAttendees from '../Components/EventAttendees';
import AddAttendeeBtn from '../Components/AddAttendeeBtn';
import AddLocation from '../Modals/AddLocation';
import AttendeeList from '../Modals/AttendeeList'
import AttendeeDetails from '../Modals/AttendeeDetails'
import CopyBox from '../Components/CopyBox';


export default function EventHomeScreen(props) {
    const [isLoading, setIsLoading] = useState(true);
    const [attendeeListModal, setAttendeeListModal] = useState(false);
    const [attendeeDetailModal, setAttendeeDetailModal] = useState(false);
    const [state, dispatchState] = useContext(Context);
    const [tableRecord, setTableRecord] = useState('')
    const [clickedAttendee, setClickedAttendee] = useState('')
    let history = useHistory();
    const Fragment = React.Fragment;


    useEffect(() => {
        // console.log('here')
        setIsLoading(true);
        dispatchState({type: 'LOADING'});
        const url_info = props.location.pathname.split("/");
        axios.get(`${process.env.REACT_APP_FLASK}/load_event/${url_info[2]}`)
            .then((res) => {
                console.log(res);
                if (res.data === null) {
                    dispatchState({type: 'EVENT_DATA', new_event_state: 'No Trip Found'});
                    dispatchState({type: 'NOT_LOADING'});
                } else {
                    dispatchState({type: 'EVENT_HOME', 
                        event: res.data.event,
                        attendees: res.data.attendees,
                        event_availability: res.data.event_availability,
                        admin_view: true
                    });
                }
                dispatchState({type: 'TRIP_ID', trip_id: url_info[2]})
                setIsLoading(false);
                dispatchState({type: 'NOT_LOADING'});
            })
            .catch((error) => {
                console.log(error.res)
                dispatchState({type: 'EVENT_DATA', event: 'Error encountered'});
                dispatchState({type: 'NOT_LOADING'});
                setIsLoading(false);
             });
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);



    const deleteAttendee = (key) => {
        let eventCopy = state.event;
        delete eventCopy.attendees[key]
        axios.delete(`${process.env.REACT_APP_FLASK}/events/${state.trip_id}/${key}`, {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                console.log(res);
                if (res.data === null) {
                    dispatchState({type: 'EVENT_DATA', event: 'Error encountered'});
                } else {
                    axios.get(`${process.env.REACT_APP_FLASK}/load_event/${state.trip_id}`)
                        .then((res) => {
                            console.log(res)
                            if (res.data === null) {
                                dispatchState({type: 'EVENT_DATA', event: 'No Trip Found'});
                            } else {
                                dispatchState({type: 'EVENT_HOME', 
                                    event: res.data.event,
                                    attendees: res.data.attendees,
                                    event_availability: res.data.event_availability,
                                    admin_view: true
                                });
                            }
                        })
                        .catch((error) => {console.log(error)})
                }
            })
            .catch((error) => {console.log(error)});
    };

    const updateLocations = (newLocations) => {
        let stateCopy = state;
        stateCopy.event.location_options = newLocations;
        dispatchState({type: 'EVENT_DATA', event: stateCopy.event});
    }

    const postNewLocations = () => {
        console.log(state.event.location_options);
        axios.patch(`${process.env.REACT_APP_FLASK}/events/${state.trip_id}/set-locations`, state.event.location_options, {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                console.log(res);
                if (res.data === null) {
                    dispatchState({type: 'EVENT_DATA', event: 'Error Occured'});
                } else {
                    dispatchState({type: 'EVENT_HOME', 
                        event: res.data.event, 
                        attendees: res.data.attendees,
                        event_availability: res.data.event_availability,
                        admin_view: true
                    });
                }
        })
            .catch((error) => {console.log(error.res)});
    }

    const soundOff = () => {
        // console.log(history);
        console.log(state);
    };

    const handleAttendeeListModal = (row) => {
        console.log(row)
        if (row === undefined) {return null}; 
        setTableRecord(row);
        setAttendeeListModal(true);

    }

    const handleAttendeeDetails = (attendee_id) => {
        setClickedAttendee(state.attendees.filter(attendee => attendee.key === attendee_id)[0])
        console.log(state.attendees.filter(attendee => attendee.key === attendee_id)[0])
        setAttendeeDetailModal(true);
    }

    const editAttendee = (attendee) => {
        dispatchState({type: 'NEW_ATTENDEE', attendee: attendee});
        history.push(`/event-signup/${state.trip_id}`);
    }

    const deleteLocationDateOption = (location_id, date_id) => {
        console.log(location_id, date_id)
        let locations_copy = state.event.location_options
        delete locations_copy[location_id].dates[date_id]
        axios.patch(`${process.env.REACT_APP_FLASK}/events/${state.trip_id}/set-locations`, locations_copy, {headers: {'Content-Type': 'application/json'}})
            .then(res => {
                console.log(res);
                if (res.data === null) {
                    dispatchState({type: 'EVENT_DATA', event: 'Error Occured'});
                } else {
                    dispatchState({type: 'EVENT_HOME', 
                        event: res.data.event, 
                        attendees: res.data.attendees,
                        event_availability: res.data.event_availability,
                        admin_view: true
                    });
                }
        })
            .catch((error) => {console.log(error.res)})
        //want to do a patch to particular location option then trigger update


    }


    if ( isLoading ) return (
        <Fragment>
            <div className={classes.MainHeading}><br/></div>;
            <Spinner animation="border" />
        </Fragment>
        )
    if (state.event === 'No Trip Found') return <div className={classes.MainHeading}>No trip found</div>;
    if (state.event === 'Error encountered') return <div className={classes.MainHeading}>Error encountered</div>;
    

    return (
            <div className={classes.WelcomePageStart} >
                <div className={classes.MainHeading} onClick= {soundOff}>{state.event.event_name}</div>
                <hr className={classes.DividerLine} />
                <EventTable data={state.event_availability} tableClick={handleAttendeeListModal}/>
                <hr className={classes.DividerLine} />
                <div className={classes.SubHeading}>Attendees</div>
                <EventAttendees attendees={state.attendees} deleteAttendee={deleteAttendee} showAttendee={handleAttendeeDetails}/>
                <hr className={classes.DividerLine} />
                <div className={classes.AddBtns}>
                    <AddLocation event={state.event} save={postNewLocations} update={updateLocations}/>
                    <AddAttendeeBtn />
                </div>
                <br/>
                <div>
                    <div>Need to send it out? Touch to copy</div>
                    <CopyBox linktext={'https://'.concat(window.location.hostname, '/event-signup/', state.trip_id)}/>
                </div>

                {state.event.attendees !== undefined &&
                    <Fragment>
                    <AttendeeList show={attendeeListModal} hide={() => {setAttendeeListModal(false)}} data={tableRecord} deleteOption={deleteLocationDateOption}/>
                    <AttendeeDetails show={attendeeDetailModal} 
                        hide={() => {setAttendeeDetailModal(false)}}
                        editAttendee={editAttendee}
                        data={clickedAttendee}
                        originalAttendee={state.event.attendees[clickedAttendee.key]}
                        locations={state.event.location_options}/>
                    </Fragment>
                    }
            </div>
    );
}