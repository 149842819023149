import React, {useContext} from 'react';
import classes from './Pages.module.css';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { SignedIn, SignedOut } from "@clerk/clerk-react";


import {Context} from '../HOCs/Store';

import EventCodeInput from '../Components/EventCodeInput';
import PlanYourEventBtn from '../Components/PlanYourEventBtn';


function StartPage(props) {
    // eslint-disable-next-line
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();

    const handlePlanYourEventBtn = () => {
        dispatchState({type: 'RESET'});
        axios.get(`${process.env.REACT_APP_FLASK}/up`, state.event )
            .then(res => (console.log(res)))
        history.push(`/plan-event-1`);
    };

    const handleEventCodeInput = (id) => {
        dispatchState({type: 'RESET'});
        dispatchState({type: 'TRIP_ID', trip_id: id})
        axios.get(`${process.env.REACT_APP_FLASK}/up`, state.event )
            .then(res => (console.log(res)))
        history.push(`/event-signup/${id}`)

    };

    return (    
            <div className={classes.WelcomePageStart}>
                <SignedOut><div className={classes.MainHeading}>Welcome to Skej!</div></SignedOut>
                <SignedIn><div className={classes.MainHeading}>Welcome back to Skej!</div></SignedIn>
                <hr className={classes.DividerLine} />
                <div className={classes.Textbox}>Your go to place to organize your next group event.</div>
                <PlanYourEventBtn handler={handlePlanYourEventBtn}/>
                <hr className={classes.DividerLine} />
                <div className={classes.Textbox}>Already have your event planned? <br/> Enter your code here:</div>
                <EventCodeInput handler={handleEventCodeInput}/>
                <div className={classes.Textbox}>Comments? Questions? Something broken? <br/> 
                    Let us know at support@theskej.com
                </div>
            </div>
    );
}



export default StartPage;