import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import classes from './ModalStyles.module.css'

function AttendeeModal(props) {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Attendees for {props.data.location} <br/> on {props.data.dates}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        { props.data.attendees !== undefined &&
        <div>{props.data.attendees.map((attendee, index) => {
            return (<div key={index}>{attendee}</div>)
          })}
          </div>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.deleteOption}>Delete Option</Button>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
}


export default function AttendeeList(props) {
  const deleteOption = () => {
    props.deleteOption(props.data.location_id, props.data.date_id);
    props.hide();
  }
  return (
      <AttendeeModal
        show={props.show}
        onHide={props.hide}
        data={props.data}
        deleteOption={deleteOption}/>
  );
}
