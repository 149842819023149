import React, { useContext } from 'react';
import { useTable, useSortBy } from 'react-table';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import { BiLocationPlus } from 'react-icons/bi';
import { MdDateRange } from 'react-icons/md'

import {Context} from '../HOCs/Store';

import classes from './Components.module.css';

function Table({ columns, data, tableClick }) {
     const {
     getTableProps,
     getTableBodyProps,
     headerGroups,
     rows,
     prepareRow,
   } = useTable({ columns, data, 
            initialState: { 
              hiddenColumns: ['attendees'] , 
              sortBy: [
                { 
                  id: 'accepted', 
                  desc: true
                }]
              }
 }, useSortBy)

   const showRowDetail = (e, row_id) => {
    const record = data.filter(obj => obj.row_id == e.target.parentElement.id)[0];
    tableClick(record);
   }

       return (
     <table {...getTableProps()} className={classes.EventTable}>
       <thead>
         {headerGroups.map(headerGroup => (
           <tr {...headerGroup.getHeaderGroupProps()}>
             {headerGroup.headers.map(column => (
               <th {...column.getHeaderProps(column.getSortByToggleProps())} className={classes.EventTableHdr}>
                 {column.render('Header')}
               </th>
             ))}
           </tr>
         ))}
       </thead>
       <tbody {...getTableBodyProps()}>
         {rows.map(row => { 
           prepareRow(row)
           return (
             <tr {...row.getRowProps()} onClick={(e) => showRowDetail(e, row.id)} id={row.id}>
               {row.cells.map(cell => {
                 return (
                   <td
                     {...cell.getCellProps()}
                     className={classes.EventTableCell}
                   >
                     {cell.render('Cell')}
                   </td>
                 )
               })}
             </tr>
           )
         })}
       </tbody>
     </table>
    )
  };


export default function EventTable(props) {
  const [state] = useContext(Context);
  let data = state.event_availability
  // eslint-disable-next-line react-hooks/exhaustive-deps
    // const data = React.useMemo(() => tableData, [])
   
    const header1 = <div>Location  {<BiLocationPlus />} </div>
    const header2 = <div>Dates <MdDateRange /> </div>
    const header3 = <div>Available <BsFillPersonCheckFill /></div>
    const header4 = <div>Attendees</div> 
    
    const columns = React.useMemo(
        () => [
            {
             Header: header1,
             accessor: 'location', // accessor is the "key" in the data
            },
            {
             Header: header2,
             accessor: 'dates',
            },
            {
             Header: header3,
             accessor: 'accepted',
            },
            {
             Header: header4,
             accessor: 'attendees',
            },
            ],
            // eslint-disable-next-line react-hooks/exhaustive-deps
            []
            )

    return <Table columns={columns} data={data} tableClick={props.tableClick} />
  }