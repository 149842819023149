import React, {useState} from 'react';
import classes from './Components.module.css';
import {MdCancel} from 'react-icons/md'

import ConfirmDelete from '../Modals/ConfirmDelete';



export default function EventAttendees(props) {
    const [attendees, setAttendees] = useState(props.attendees);
    const [modalShow, setModalShow] = useState(false);
    const [toDelete, setToDelete] = useState('');

    if (props.attendees === undefined) {
        return null
    }

    const deleteAttendee = () => {
        setAttendees(attendees.filter(attendee => attendee.key !== toDelete));
        props.deleteAttendee(toDelete);
        setToDelete('');
        setModalShow(false);
    };

    const promptDeleteAttendee = (attendee) =>{
        setToDelete(attendee);
        setModalShow(true);
    };

    return (
        <div className={classes.AttendeeBox}>
            {attendees.map(attendee => {
                return(
                    <div className={classes.AttendeeCard} key={attendee.key} >
                        <div className={classes.Cardcol1} 
                            onClick={() => props.showAttendee(attendee.key)}>{attendee.name}</div> 
                        <div className={classes.Cardcol2} 
                            onClick={() => {promptDeleteAttendee(attendee.key)}}><MdCancel className={classes.DeleteIcon}/></div>
                        <ConfirmDelete
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                            yes={deleteAttendee}
                            cancel={() => {setModalShow(false); setToDelete('');} }
                        />


                    </div>
                    )

                })
            }
        </div>
        )
}