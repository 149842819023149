import React from 'react'
import classes from './Components.module.css'

export default function AttendeeEmailEntry (props) {
    return (
        <input className={classes.EntryBox} 
            type="email"
            id="email_entry"
            name="attendee_email"
            placeholder="Enter email"
            value={props.attendeeEmail}
            onChange={props.handler} />
    );
}
