const Reducer = (state, action) => {
    const blankState = {
    'trip_id' : '',
    'admin_view': false,
    'event': {
        "attendees": {},
        "date_planned" : false,
        "location_options": {},
        "owner": "demo@demo.com",
        "event_leader": "",
        "event_leader_email": "",
        "event_id": "",
        "event_name": "",
        "attendee_can_view": true,
    },
    'attendee' : {
        name: '', 
        email: '',
        location_options: {}
    },
    'emails' : {},
    'isLoading' : false
};


    switch (action.type) {
        case 'PLAN_EVENT':
            console.log(action.state)
            return action.state;

        case 'ATTENDEE':
            return {...state, event: action.event, trip_id: action.trip_id, attendee: action.attendee}
        
        case 'NEW_ATTENDEE':
            return {...state, attendee: action.attendee}

        case 'EVENT_DATA':
            return {...state, event: action.event}

        case 'EVENT_HOME':
            return {...state, event: action.event, attendees: action.attendees, event_availability: action.event_availability, admin_view: action.admin_view }

        case 'TRIP_ID':
            return {...state, trip_id: action.trip_id}

        case 'DATE_PLANNED':
            let eventCopy = state.event
            eventCopy.date_planned = action.date_planned
            return {...state, event: eventCopy}

        case 'ATTENDEE_EMAILS':
            return {...state, emails: action.emails}

        case 'LOADING':
            return {...state, isLoading: true}

        case 'NOT_LOADING':
            return {...state, isLoading: false}

        case 'RESET':
            return blankState

        default:
            console.log('no updates occurred')
            return state;
    }
};

export default Reducer;