import React from 'react';
import dayjs from 'dayjs';
import { TiDelete } from "react-icons/ti";
import classes from './Components.module.css'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { ReactFitty } from "react-fitty";
import {datePrepare} from '../Special/day_formatting'



export default function SelectedDateDisplayBoxAttendee(props){

    const deleteSelectedDate = (loc_id, date_id) => {
        console.log('deleting');
        props.delete(loc_id, date_id);
    };

    const accordianGenerator = (name, dateSet, loc_id, date_id) => {
        return (
            <div className={classes.SelectedDateBox} 
                 key={loc_id.concat(date_id)}>
                <div className={classes.SelectedDateBoxItem}>{name}</div> 
                <div className={classes.SelectedDateBoxItem}>
                    <ReactFitty maxSize={16}>
                        {datePrepare(dateSet)}
                    </ReactFitty>
                </div>
                <div className={classes.SelectedDateBoxItem}>
                    {<TiDelete size={20} className={classes.DeleteIcon} onClick={() => {deleteSelectedDate(loc_id, date_id)}}/>}
                </div>
            </div>
                )    
    };

    const soundOff = () => {

    }

    console.log(props.locations);
    if (Object.entries(props.locations).some((loc) => Object.keys(loc[1].chosen_dates).length !== 0)) {
        return (
            <Accordion onClick={soundOff} style={{width:"80%", margin:"auto"}}>
              <Card>
                <Card.Header style={{'padding':"0"}}> 
                  <Accordion.Toggle as={Button} style={{width:"100%"}} variant="primary" eventKey="0">
                    View Selected Dates
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body style={{'paddingLeft':"0", 'paddingRight':"0"}}>
                 {Object.entries(props.locations).map(([top_id, location]) => {
                    return Object.entries(location.chosen_dates).map(([sec_id, dateSet]) => {
                        return (
                            accordianGenerator(location.location_name, dateSet, top_id, sec_id)
                        )
                    })
                })}
                     </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>

            )
    } else {
        return <div onClick={soundOff}></div>
    }
}