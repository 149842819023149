import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import {useHistory} from 'react-router-dom'

import classes from './ModalStyles.module.css'
import LocationBox from '../Components/LocationBox'

function LocationModal(props) {
  return (
    <Modal
      onHide={props.onHide}
      show={props.show}
      size="lg"
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Add Locations
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{'textAlign':'center'}}>
        <LocationBox fromModal={true} location_options={props.data.location_options} save={props.save}/>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.confirm}>Confirm</Button>
        {/* <Button onClick={props.onHide}>Cancel</Button> */}
      </Modal.Footer>
    </Modal>
  );
}


export default function AddLocation(props) {
  const [modalShow, setModalShow] = useState(false);
  let history = useHistory();

  const confirmChanges = () => {
    props.save()
    setModalShow(false);
  };

  const handleType = () => {
    if (props.event.date_planned === true) {
      history.push('/plan-event-2')
    } else {
      setModalShow(true);
    }
  }


  return (
    <div className={classes.ModalBtn}>
      <Button variant="primary" onClick={handleType} className={classes.ModalBtn}>
        + Add New Location
      </Button>

      <LocationModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        data={props.event}
        confirm={confirmChanges}
        save={props.update}
      />
    </div>
  );
}
