import React , { useContext } from "react";
import axios from 'axios';
import classes from './Pages.module.css';
import {useHistory} from 'react-router-dom';
import dayjs from 'dayjs';

import {Context} from '../HOCs/Store';
import CalendarPicker from '../Components/CalendarPicker';
import SelectedDateDisplayBox from '../Components/SelectedDateDisplayBox';
import NextBtn from '../Components/NextBtn';

export default function PlannerPageCalendar(props) {
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();

    const updateEvent = () => {
      dispatchState({type: 'LOADING'})
      axios.post(`${process.env.REACT_APP_FLASK}/update-event/${state.trip_id}`, state.event )
        .then(res => {
          console.log(res);
          console.log(res.data);
          dispatchState({type: 'NOT_LOADING'})
        })
        .catch(error => {
           console.log(error.response)
        });

      history.push('plan-event-3');
    };

    const soundOff = () => {
        console.log(state.event.location_options);
        // console.log(workingLocations)
    };

    const checkDatesArePresent = (dateSet, startDate, endDate) => {
      //converting to string to check for presence of dats because checking for objects doesnt work
      if (
          dayjs(startDate).format('YYYY-MM-DD') === dayjs(dateSet[0]).format('YYYY-MM-DD') &&
          dayjs(endDate).format('YYYY-MM-DD') === dayjs(dateSet[1]).format('YYYY-MM-DD')
          ) 
      {return true} 
      else {return false}
    }

    const updateLocations = (workingLocations, startDate, endDate) => {
      let eventCopy = state.event
      console.log(Object.entries(eventCopy.location_options))
      Object.entries(eventCopy.location_options).forEach(([id, location], index) => {
          let new_date_id = Math.random().toString(36).substring(2, 7) + Math.random().toString(36).substring(5, 10);
          if (location.location_id in workingLocations) {
            if (Object.values(location.dates).some((dateSet) => checkDatesArePresent(dateSet, startDate, endDate))) {
              return
              } else {
              eventCopy.location_options[id]['dates'][new_date_id] = [startDate, endDate];
              console.log(eventCopy)
            }
          }
        
        })
        dispatchState({type: 'EVENT_DATA', event: eventCopy })
    }

    const removeDateFromLocation = (loc_id, date_id) => {
      let eventCopy = state.event
      // console.log(loc_id, date_id)
      // console.log(eventCopy.location_options[loc_id].dates[date_id])
      delete eventCopy.location_options[loc_id].dates[date_id]
      // console.log(eventCopy)
      dispatchState({type: 'EVENT_DATA', event: eventCopy })

    }
    

  if (state.trip_id === '') {
    history.push('plan-event-1');
  };

    return (
        <div className={classes.PlannerPageStart}>
          <div className={classes.MainHeading}
            onClick={soundOff}>What are the date options for {state.event.event_name}?</div>
          <hr className={classes.DividerLine} />
          <CalendarPicker handler={updateLocations} event_data={state.event} />
          <SelectedDateDisplayBox locations={state.event.location_options} delete={removeDateFromLocation} />
           <NextBtn link={''}
              handler={updateEvent}
              back={true}
              change={history.goBack}
              text='Next'/>
        </div>

            )
}