import React , { useContext, useState } from "react";
import classes from './Pages.module.css';
import axios from 'axios';
// import {NotificationManager} from 'react-notifications';
import {useHistory} from 'react-router-dom';

import {Context} from '../HOCs/Store';

import NextBtn from '../Components/NextBtn';
import AttendeeEmails from '../Components/AttendeeEmails';
import AddEmailsSwitch from '../Components/AddEmailsSwitch';
import CopyBox from '../Components/CopyBox';
import AllowAttendeesToView from '../Components/AllowAttendeesToView';


export default function PlannerPage3(props) {
  const [state, dispatchState] = useContext(Context);
  const [emailSent, setEmailSent] = useState(false);
  const [shouldPost, setShouldPost] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  let history = useHistory();


  function redirectEventHome() {
    if (shouldPost) {
        axios.patch(`${process.env.REACT_APP_FLASK}/update-event/${state.trip_id}`, {'attendee_can_view' : state.event.attendee_can_view} )
          .then(res => {
            console.log(res.status);
            dispatchState({type: 'NOT_LOADING'});
          })
          .catch(error => {
             console.log(error.response);
             dispatchState({type: 'NOT_LOADING'});
          });

      }
      history.push(`/events/${state.trip_id}`)
    };

    if (state.trip_id === '') {
      history.push('plan-event-1');
    };

    if (emailSent===false) {
      setEmailSent(true);
      if (state.event.event_leader_email === '' ) {
        console.log('not emailing');
      } else {
      axios.get(`${process.env.REACT_APP_FLASK}/trigger_email/${state.trip_id}`)
          .then(res => {
            console.log(res.status);
          })
          .catch(error => {
             console.log(error.response)
          });
      };
    }

    const allowAttendeesToView = (value) => {
      let eventCopy = state.event
      eventCopy.attendee_can_view = value
      dispatchState({'type': 'EVENT_DATA', 'event': eventCopy})
      setShouldPost(true);
      console.log(state.event)
    }

    // const emailEnabled = () => {
    //   if (state.event.event_leader_email === ''){
    //     setEmailSwitchEnabled(false);
    //   } else {
    //     setEmailSwitchEnabled(true);
    //   }
    // }

    return (<div className={classes.PlannerPageStart}>
                <div className={classes.MainHeading}>Your event has been planned!</div>
                <hr className={classes.DividerLine} />
                <div className={classes.MainHeading}>Now it's time to send the link to the attendees!</div>
                <div className={classes.SubHeading}>Tap on the link to copy:</div>
                <CopyBox linktext={'https://'.concat(window.location.hostname, '/event-signup/', state.trip_id)}/>
                <div className={classes.Textbox}>Don't worry, you can always go back and edit the event.</div>
                <NextBtn 
                  handler={redirectEventHome}
                  back={true}
                  text='View the Event!'/>
                <hr className={classes.DividerLine} />
                <AllowAttendeesToView  handler={allowAttendeesToView}/>
                <AddEmailsSwitch handler={setShowEmail}/>
                { showEmail ? <AttendeeEmails /> : null }
             </div>)
    };
