import React , { useContext } from "react";
import {Link} from "react-router-dom";
import {Context} from '../HOCs/Store';



export const ManageEventCodeInput = (props) => {
    const [state, dispatchState] = useContext(Context);

    const setEvent = (e: ChangeEvent<HTMLInputElement>) => {
      console.log(e.target.value)
      dispatchState({type: 'TRIP_ID', trip_id: e.target.value});
    };

    return (
          <div>
            <input type="text" value={state.trip_id} onChange={setEvent} />
            <Link
              to={{
                pathname: "/events/" + state.trip_id
              }}>
              <button type="button" className="btn btn-success">Go!</button>
            </Link>
          </div>
    )


};

