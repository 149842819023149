import React from 'react'
import Form from 'react-bootstrap/Form';

export default function ChooseDatesSwitch(props) {
    const handleCheck = (e) => {
        props.handler(e.target.checked);
    }
    return (
            <Form>
              <Form.Check 
                type="switch"
                id="add_email"
                label="Email event to attendees"
                onChange={handleCheck}
                // checked={value}
                // disabled={disabled}
            />
            </Form>
        )
}