import React from 'react'
import Form from 'react-bootstrap/Form';

export default function ChooseDatesSwitch(props) {
    const handleCheck = (e) => {
        props.handler(e.target.checked);
    }

    let value = props.value;
    if (value === undefined) {
        value = false;
    }

    let disabled = false;
    if (props.active !== undefined) {
        if (Object.keys(props.active).length > 0) {
            disabled = true;
        }
    }

    return (
            <Form>
              <Form.Check 
                type="switch"
                id="choose-dates"
                label="Preselect Dates for your Event"
                onChange={handleCheck}
                checked={value}
                disabled={disabled}
            />
            </Form>
        )
}