import React from 'react';
import dayjs from 'dayjs';

export const datePrepare = (dateSet) => {
        const Fragment = React.Fragment;
        if (dayjs().format('YYYY') !== dayjs(dateSet[0]).format('YYYY') || dayjs(dateSet[0]).format('YYYY') !== dayjs(dateSet[1]).format('YYYY')) {
                    return ( 
                        <Fragment>
                        {dateSet[0]===dateSet[1] ?
                            dayjs(dateSet[0]).format('MM/DD/YY')    
                            :
                            dayjs(dateSet[0]).format('MM/DD/YY').concat(' - ',dayjs(dateSet[1]).format('MM/DD/YY'))
                        } 
                        </Fragment>
                    )
            } else {
                return ( 
                    <Fragment>
                    {dateSet[0]===dateSet[1] ?
                            dayjs(dateSet[0]).format('MM/DD')    
                            :
                            dayjs(dateSet[0]).format('MM/DD').concat(' - ',dayjs(dateSet[1]).format('MM/DD'))
                    }
                    </Fragment>
                )
            }
}