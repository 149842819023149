import React, { useState } from 'react';
import classes from './Components.module.css';
import './Calendar.css';
import { NotificationManager } from 'react-notifications';

import dayjs from 'dayjs'
import Calendar from 'react-calendar';

export default function CalendarPicker(props) {
    const [workingLocations, setWorkingLocations] = useState(Object.assign({}, props.event_data.location_options));
    const [startDate, setStart] = useState('');
    const [endDate, setEnd] = useState('');
    // useEffect(() => {
    //     props.save(workingLocations, startDate, endDate)
    // // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [workingLocations]);

    const selectLocationHandler = (checked, location) => {
        let wLCopy = workingLocations;
        if (checked === true) {
            const id = location['location_id']
            wLCopy[id] = location.location_name;
        } else if (checked === false ) {
            delete wLCopy[location.location_id];
        }
        setWorkingLocations(wLCopy);
    };

    const divClickCheckHandler = (e, location) => {
        if (e.target.children.length === 0) {return};
        e.target.children[0].checked = !(e.target.children[0].checked)
        selectLocationHandler(e.target.children[0].checked, location)
    }

    function boxGen([id, location], index) {
        return (<div className={classes.LocationBox} 
            key={location.location_id}
            onClick={(e) => {divClickCheckHandler(e, location)}} >
                        {index + 1}) {location.location_name}
                        <input type="checkbox"
                          name="location"
                          defaultChecked={true}
                          id={`radio${index}`}
                          className={classes.radioBtn}
                          onChange = {(e) => {selectLocationHandler(e.target.checked, location)}}
                           />
                </div>)
    };

    const chooseDates = (value, event) => {
        setStart(value[0])
        setEnd(value[1])
    };

    const startDisplayText = () => {
        let userStartDate = '__'
        if (startDate !== '') {
            userStartDate = dayjs(startDate).format('MM-DD')
        }
        return userStartDate
    }

    const endDisplayText = () => {
        let userEndDate = '__'
        if (endDate !== '') {
            userEndDate = dayjs(endDate).format('MM-DD')
        }
        return userEndDate

    }

    const addDateRangeToLocation = () => {
        if (Object.keys(workingLocations).length === 0) {
            NotificationManager.error('Please select a location', '', 2000);
            return null;
        } else {
            props.handler(workingLocations, startDate, endDate)
            NotificationManager.success('Dates added!', '', 2000);
        }
    };

    const soundOff = () => {
        console.log(workingLocations);
    };

    const exp = (e) => {
        console.log(e);
    }

    const dateClicked = (e) => {
        setStart(e);
        setEnd(e);   
    };

  return (
    <div>
        <div className={classes.LocationBoxesDiv}>
                        {Object.entries(props.event_data.location_options).map(boxGen)}
        </div>
        <div className={classes.CalendarDiv}>
          <Calendar
            onChange={chooseDates}
            selectRange={true}
            onClickDay={dateClicked}
            onClickMonth={exp}
            minDate={new Date()}
          />
          <div onClick={soundOff}>Chosen Dates: 
            <span className={classes.DateSpan}>{startDisplayText()}</span> 
            to 
            <span className={classes.DateSpan}>{endDisplayText()} </span>
          </div>
          <button className={classes.NextBtn + " btn btn-success"}
                    onClick={addDateRangeToLocation}>
                Confirm Dates and Add More
          </button>
        </div>
    </div>
  );
}
