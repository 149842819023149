import React , { useContext } from "react";
import axios from 'axios';
import classes from './Pages.module.css';
import {NotificationManager} from 'react-notifications';
import {useHistory} from 'react-router-dom'

import {Context} from '../HOCs/Store';

import NextBtn from '../Components/NextBtn';
import LocationBox from '../Components/LocationBox';
import ChooseDatesSwitch from '../Components/ChooseDatesSwitch'


export default function PlannerPage2(props) {
    const [state, dispatchState] = useContext(Context);
    let history = useHistory();

    const updateForm = (e) => {
      let stateCopy = state
      switch (e.target.name) {
        case 'event_name':
          stateCopy.event.event_name = e.target.value;
          break;
        case 'event_leader_email':
          stateCopy.event.event_leader_email = e.target.value;
          break;
        case 'location_options':
          stateCopy.event.location_options = e.target.value;
          break;
        default:
          console.log(e.target.name);
          console.log(e.target.value);

      };
          dispatchState({type: 'EVENT_DATA', event: stateCopy.event});
    };


  function updateEvent(){
    if (Object.entries(state.event.location_options).length === 0 ) {
        NotificationManager.error('Please enter at least one location', '', 2000);
        return null;
    }
      dispatchState({type: 'LOADING'})
      axios.post(`${process.env.REACT_APP_FLASK}/update-event/${state.trip_id}`, state.event )
        .then(res => {
          console.log(res.status);
          dispatchState({type: 'NOT_LOADING'});
        })
        .catch(error => {
           console.log(error.response);
           dispatchState({type: 'NOT_LOADING'});
        });
      if (document.getElementById('choose-dates').checked === true) {
        let stateCopy = state
        Object.entries(stateCopy.event.location_options).map(([id, option]) => {
          if (option['dates']=== undefined) {
            option['dates'] = {}
          }
        })
        dispatchState({type: 'EVENT_DATA', event: stateCopy.event});
        history.push(`/plan-event-dates`)
      } else {
        history.push(`/plan-event-3`)
      }
  };

  function soundOff() {
    console.log(state);
  };

  const preSelectSet = (val) => {
    dispatchState({type: 'DATE_PLANNED', date_planned: val});
  };

  if (state.trip_id === '') {
    history.push('plan-event-1');
  };


    return (<div className={classes.PlannerPageStart}>
            <div className={classes.MainHeading} onClick={soundOff}>Where are you going for {state.event.event_name}?</div>
            <LocationBox location_options={state.event.location_options} save={updateForm}/>
            <hr className={classes.DividerLine} />
            <ChooseDatesSwitch handler={preSelectSet} active={state.event.attendees} value={state.event.date_planned}/>
            <NextBtn link={''}
              handler={updateEvent}
              back={true}
              change={history.goBack}
              text='Next'/>
         </div>)

}

