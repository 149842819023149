import React from 'react';
import {NotificationManager} from 'react-notifications';

export default function EventCodeInput (props) {

    const getEvent = (e) => {
      // console.log(document.getElementById("codeInput").value)
      if (document.getElementById("codeInput").value === '') {
        NotificationManager.error('Please enter a code', '', 2000);
      } else {
        let code = document.getElementById("codeInput").value
        props.handler(code.trim())
      }
    };


    return (
      <div>
        <input type="text" id="codeInput" />
        <button type="button" onClick={getEvent} className="btn btn-success">Go!</button>
      </div>
    );
  }


