import React from 'react';
import classes from './Pages.module.css';

export default function NotFound(props) {
    console.log('not found page loaded')
    return (    
            <div className={classes.WelcomePageStart}>
                <div className={classes.MainHeading}>Sorry, this page does not exist.</div>
            </div>
    );
}